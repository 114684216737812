/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Survey from 'survey-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import api from '~api';
import { useCampaign } from '~Flow';
import useGetTemplate from '~hooks/useGetTemplate';
import ErrorPage from '../ErrorPage';
import Loader from '~components/Loader';
import OpinaiaLogo from '~images/Opinaia.svg';
import PremiumLogoEn from '~images/Premium-Encuestas_logo_ENG.png';
import PremiumLogoEs from '~images/Premium-Encuestas_logo_ESP.png';
import PremiumLogoPt from '~images/Premium-Encuestas_logo_POR.png';
import DangerouslySetHtmlContent from '~components/DangerouslySetHtmlContent';
import useQuery from '~hooks/useQuery';
import 'survey-react/survey.css';
import './styles.scss';

const defaultThemeColors = Survey.StylesManager.ThemeColors.default;
defaultThemeColors['$main-color'] = '#7ae261';
defaultThemeColors['$main-hover-color'] = '#64C150';
defaultThemeColors['$text-color'] = '#6a829a';

Survey.StylesManager.applyTheme();
Survey.Serializer.addProperty('question', 'surveyId:string');

const isQuestionSingleAnswer = question =>
  question.choices?.length && typeof question.maxSelectedChoices === 'undefined';

const setShowNavigationButtons = survey => {
  const { questions } = survey.currentPage;
  if (questions.length === 1 && isQuestionSingleAnswer(questions[0]) && !survey.isLastPage) {
    survey.showNavigationButtons = 'none';
  } else {
    survey.showNavigationButtons = 'bottom';
  }
};

let init = true;
let doAnimantion = false;

const handleRenderPage = () => {
  if (!init) return;
  doAnimantion = true;
  init = false;
};

const handlePageChanging = (sender, options) => {
  if (init) return;

  if (!doAnimantion) return;
  options.allowChanging = false;
  const [surveyEl] = document.getElementsByClassName('sv_main');
  if (!surveyEl) return;

  doAnimantion = false;

  const doNextPage = () => {
    if (options.isNextPage) sender.currentPage = options.newCurrentPage;
    if (options.isPrevPage) sender.prevPage();
    doAnimantion = true;
    surveyEl.removeEventListener('animationend', doNextPage);

    setShowNavigationButtons(sender);
  };

  setTimeout(() => {
    surveyEl.classList.remove('show');
    surveyEl.addEventListener('animationend', doNextPage);
    surveyEl.classList.add('hide');
  }, 0);
};

const handleManualChangePage = survey => {
  if (!doAnimantion) return;
  const [surveyEl] = document.getElementsByClassName('sv_main');
  if (!surveyEl) return;

  doAnimantion = false;

  const doNextPage = () => {
    survey.nextPage();
    doAnimantion = true;
    surveyEl.removeEventListener('animationend', doNextPage);

    setShowNavigationButtons(survey);
  };

  setTimeout(() => {
    surveyEl.classList.remove('show');
    surveyEl.addEventListener('animationend', doNextPage);
    surveyEl.classList.add('hide');
  }, 500);
};

const handleCompleting = (sender, options) => {
  if (!doAnimantion) return;
  options.allowComplete = false;
  const [surveyEl] = document.getElementsByClassName('sv_main');
  if (!surveyEl) return;

  doAnimantion = false;

  surveyEl.addEventListener('animationend', () => {
    sender.doComplete();
  });
  surveyEl.classList.remove('show');
  surveyEl.classList.add('hide');
};

const handleServerValidateQuestions = async (survey, options) => {
  const { complete, data, errors } = options;

  if (data.zipcode) {
    const [zipcodeQuestion] = document.getElementsByName('zipcode');
    const [zipcodeInput] = zipcodeQuestion.getElementsByClassName('question_content');
    try {
      zipcodeInput.classList.add('loading');
      const response = await api.getLocalizationByName(data.zipcode);
      if (response.data.length) {
        const [zipcodeData, countyData, stateData] = response.data[0];
        survey.mergeData({
          localization: zipcodeData.id,
          city: countyData.name,
          district: stateData.name,
        });
      } else {
        errors.zipcode = 'Your zipcode does not seem to exist';
      }
    } catch (err) {
      errors.zipcode = "Connection error. We coudn't verify this zipcode";
      console.warn(err.toJSON());
    } finally {
      zipcodeInput.classList.remove('loading');
    }
  }
  complete();
};

const SurveyPage = () => {
  const [recaptchaToken, setRecaptchaToken] = useState();
  const [districtName, setDistrictName] = useState('');

  const { t } = useTranslation('common');
  const recaptchaRef = useRef();
  const query = useQuery();
  const { campaign, url, setUserData, setProvider, provider, setLeadId, leadId, setError } =
    useCampaign();
  const { surveyJson, registerFields, registerId, termsModalHtml, error, loading } =
    useGetTemplate();
  const history = useHistory();

  useEffect(() => {
    if (!provider) setProvider(query.provider);
    if (!leadId) setLeadId(query.leadId);
  }, [provider, leadId]);

  if (error) return <ErrorPage errorCode={error} />;

  if (loading || !surveyJson) return <Loader />;

  const handlePageChanged = survey => {
    const [surveyEl] = document.getElementsByClassName('sv_main');
    if (!surveyEl) return;

    if (survey.isLastPage) {
      recaptchaRef.current?.execute();
    }

    surveyEl.classList.remove('hide');
    surveyEl.classList.add('show');
  };

  const handleValueChanged = (survey, options) => {
    const { questions } = survey.currentPage;

    if (questions.length === 1 && isQuestionSingleAnswer(questions[0]) && !survey.isLastPage) {
      handleManualChangePage(survey);
    }

    if (options.name === 'sex') {
      const [date] = document.getElementsByName('dateOfBirth');
      if (!date) return;
      if (campaign.Language === 'en') {
        const [month, day] = date.getElementsByTagName('input');
        if (!day || !month) return;

        month.focus();
      } else {
        const [day, month] = date.getElementsByTagName('input');
        if (!day || !month) return;

        day.focus();
      }
    }

    if (options.name === 'district') {
      const { displayValue } = options.question.getPlainData();
      setDistrictName(displayValue);
    }

    if (options.name === 'localization') {
      const { displayValue } = options.question.getPlainData();
      survey.setValue('city', displayValue);
    }
  };

  const handleAfterRenderQuestion = (_, options) => {
    const { question, htmlElement } = options;

    if (question.name === 'dateOfBirth') {
      if (campaign.Language === 'en') {
        const [month, day, year] = htmlElement.getElementsByTagName('input');
        if (!day || !month || !year) return;

        month.addEventListener('input', e => {
          if (e.target.value.length === 2) day.focus();
        });

        day.addEventListener('input', e => {
          if (e.target.value.length === 2) year.focus();
        });
      } else {
        const [day, month, year] = htmlElement.getElementsByTagName('input');
        if (!day || !month || !year) return;

        day.addEventListener('input', e => {
          if (e.target.value.length === 2) month.focus();
        });

        month.addEventListener('input', e => {
          if (e.target.value.length === 2) year.focus();
        });
      }
    }
  };

  const handleAfterRenderQuestionInput = (_, options) => {
    const { question, htmlElement } = options;

    if (question.name === 'terms') {
      const [termsLabel] = htmlElement.getElementsByClassName('sv-string-viewer');
      if (!termsLabel) return;
      termsLabel.innerHTML = `${t('accept')} <span id="terms">${t('tyc')}</span> ${t(
        'and',
      )} <span id="privacy">${t('privacy')}</span>.`;

      const termsBtn = document.getElementById('terms');
      const termsModal = document.getElementById('modal-terms');
      const termsClose = document.getElementById('close-terms');
      const privacyBtn = document.getElementById('privacy');
      const privacyModal = document.getElementById('modal-privacy');
      const privacyClose = document.getElementById('close-privacy');
      const contents = [...document.getElementsByClassName('modal__content')];
      if (
        !termsBtn ||
        !termsModal ||
        !termsClose ||
        !privacyBtn ||
        !privacyModal ||
        !privacyClose ||
        !contents ||
        !contents.length
      )
        return;

      termsBtn.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        termsModal.classList.add('show-modal');
      });
      privacyBtn.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        privacyModal.classList.add('show-modal');
      });

      [termsClose, termsModal].forEach(item =>
        item.addEventListener('click', () => {
          termsModal.classList.remove('show-modal');
        }),
      );
      [privacyClose, privacyModal].forEach(item =>
        item.addEventListener('click', () => {
          privacyModal.classList.remove('show-modal');
        }),
      );

      contents.forEach(content =>
        content.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          return false;
        }),
      );
    }
  };

  const handleComplete = async survey => {
    const recaptcha = recaptchaToken;
    const { terms, ...surveyData } = survey.data;
    const { dateOfBirth: { day, month, year } = {} } = surveyData;

    const registerFieldsWithLocalization = [
      ...new Set([...registerFields, 'localization', 'city', 'district']),
    ];

    const dateOfBirth = [year, month, day].join('-');

    // Transforma los nombres de las preguntas para que tengan el id de la survey
    // y corrige el contenido de dateOfBirth a un string y corrige el distrito que
    // se trae por API
    const responses = Object.entries(surveyData)
      .reduce((acc, [question, response]) => {
        if (question === 'dateOfBirth') {
          return [...acc, { question, sid: registerId, response: dateOfBirth }];
        }

        if (question === 'district' && districtName) {
          return [...acc, { question, sid: registerId, response: districtName }];
        }

        if (registerFieldsWithLocalization.includes(question)) {
          return [...acc, { question, sid: registerId, response }];
        }

        const { surveyId } = survey.getQuestionByName(question);
        return [...acc, { question, sid: surveyId, response }];
      }, [])
      .sort((a, b) => a.sid.localeCompare(b.sid));

    const answersWithoutRegisterItems = {
      register: {
        campaign: `FlowPopular2021 - ${campaign.Name}${
          campaign['Recruitment ID'] ? ` - ${campaign['Recruitment ID']}` : ''
        }`,
        ...(provider && { provider }),
        ...(leadId && { leadId }),
      },
      responses,
      campaignID: campaign.id,
      recaptchaToken: recaptcha,
      external: campaign.Tipo !== 'Opinaia',
    };

    // Se agregan los items de registro con sus respuestas por separado
    const answers = registerFieldsWithLocalization.reduce((acc, curr) => {
      const { [curr]: registerField } = surveyData;

      if (curr === 'dateOfBirth') {
        return {
          ...acc,
          register: { ...acc.register, [curr]: dateOfBirth },
        };
      }
      if (curr === 'district' && districtName) {
        return {
          ...acc,
          register: { ...acc.register, [curr]: districtName },
        };
      }

      return {
        ...acc,
        register: { ...acc.register, [curr]: registerField },
      };
    }, answersWithoutRegisterItems);

    setUserData(answers.register);

    const saveAnswers = async () => {
      try {
        history.push(`${url}/await`);

        if (campaign.Tipo === 'Opinaia') {
          const {
            data: { token },
          } = await api.putSurveyAnswers(answers);
          setUserData(prev => ({ ...prev, token }));
        } else {
          await api.putSurveyAnswers(answers);
        }
      } catch (err) {
        if (err.response.status === 409) {
          history.push(`${url}/already-registered`);
        } else {
          setError(err.response.status);
          history.push(`${url}/error`);
        }
        console.warn(`${err.response.status}: `, err.response.data);
      }
    };

    if (registerId) {
      await saveAnswers();
    } else if (campaign.Ending) {
      history.push(`${url}/ending`);
    } else {
      if (!campaign['Redirect URL']) throw new Error('Redirect URL missing');
      window.location.href = campaign['Redirect URL']
        .replace('{{provider}}', provider || '')
        .replace('{{leadId}}', leadId || '')
        .replace('{{firstname}}', encodeURIComponent(answers.register?.firstname || ''))
        .replace('{{lastname}}', encodeURIComponent(answers.register?.lastname || ''))
        .replace('{{email}}', encodeURIComponent(answers.register?.email || ''))
        .replace('{{gender}}', encodeURIComponent(answers.register?.sex || ''))
        .replace('{{dateOfBirth}}', encodeURIComponent(answers.register?.dateOfBirth || ''))
        .replace('{{country}}', encodeURIComponent(answers.register?.country || ''))
        .replace('{{district}}', encodeURIComponent(answers.register?.district || ''))
        .replace('{{city}}', encodeURIComponent(answers.register?.city || ''))
        .replace('{{zipcode}}', encodeURIComponent(answers.register?.zipcode || ''))
        .replace('{{localizationId}}', encodeURIComponent(answers.register?.localization || ''));
    }
  };

  return (
    <div className={`survey${campaign.Tipo === 'Premium' ? ' premium' : ''}`}>
      <header className={`header${campaign.Tipo === 'Premium' ? ' premium' : ''}`}>
        {campaign.Tipo === 'Opinaia' ? (
          <>
            <OpinaiaLogo className="header__logo" />
            <p
              className="ad"
              dangerouslySetInnerHTML={{
                __html: campaign['Text over survey'],
              }}
            />
          </>
        ) : (
          <>
            {campaign.Language === 'en' && (
              <img src={PremiumLogoEn} className="header__logoPremium" alt="" />
            )}

            {campaign.Language === 'es' && (
              <img src={PremiumLogoEs} className="header__logoPremium" alt="" />
            )}
            {campaign.Language === 'pt-br' && (
              <img src={PremiumLogoPt} className="header__logoPremium" alt="" />
            )}
          </>
        )}
      </header>
      <div className="survey__container">
        <Survey.Survey
          json={surveyJson}
          locale={campaign.Language}
          onComplete={handleComplete}
          onCurrentPageChanging={handlePageChanging}
          onCurrentPageChanged={handlePageChanged}
          onCompleting={handleCompleting}
          onAfterRenderPage={handleRenderPage}
          onServerValidateQuestions={handleServerValidateQuestions}
          onValueChanged={handleValueChanged}
          onAfterRenderQuestionInput={handleAfterRenderQuestionInput}
          onAfterRenderQuestion={handleAfterRenderQuestion}
          onAfterRenderSurvey={survey => {
            if (survey.currentPage) setShowNavigationButtons(survey);
          }}
          showPrevButton={false}
          showTitle={false}
          showQuestionNumbers="off"
          showProgressBar="top"
          questionErrorLocation="bottom"
          css={{
            progressTextUnderBar: 'sv_progress_text',
            radiogroup: { labelChecked: 'checked' },
            question: {
              content: 'question_content',
            },
          }}
        />

        <DangerouslySetHtmlContent html={termsModalHtml} />

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.GOOGLE_RECAPTCHA_KEY}
          onChange={setRecaptchaToken}
          lang={campaign.Language}
        />
      </div>

      <small className="google-policies">
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </small>

      <footer className={`footer${campaign.Tipo === 'Premium' ? ' premium' : ''}`}>
        <p>
          © {new Date().getFullYear()}{' '}
          {campaign.Tipo === 'Premium' ? t('premiumName') : campaign.Tipo}
        </p>
      </footer>
    </div>
  );
};

export default SurveyPage;
